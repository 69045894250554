.configure-database-content {
    background-color: #ECE9F0;
    max-width: 100%;
    padding: 10px;
    padding-left: 50px;
    display: grid;
    grid-template-rows: repeat(3, 225px);
}

.configure-database-img1{
     width: 700px;
     height: 210px;
}


.configure-database-img2{
    width: 700px;
    height: 210px;
}

.configure-database-img3{
    width: 700px;
    height: 220px;
}



