
.content {
    background-color: #ECE9F0;
    max-width: 100%;
    padding: 40px;
    padding-left: 50px; 
    display: grid;
    grid-template-columns: repeat(3, 320px);
    grid-template-columns: repeat(auto-fill, minmax(1px, 320px));
  
}

.four-img-content {
    background-color: #ECE9F0;
    max-width: 100%;
    padding: 40px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: repeat(4, 270px);
    grid-template-columns: repeat(auto-fill, minmax(1px, 265px));
}

.img {
    width: 300px;
    height: 600px;
}

.alien-img {
    margin-top: 150px;
    width: 250px;
    height: 350px;
}

.four-img {
    width: 260px;
    height: 500px;
}