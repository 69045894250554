.container {
    background-color: steelblue;
    max-width: auto;
    justify-self: left;
    padding: 15px;
    font-size: 10px;
}

button {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;

    color: white;
    font-size: 1rem;
    line-height: 1;
    text-decoration: none;
    padding-right: 10px;
}

button:hover{
    color: rgb(0, 255, 85);
}

.nav-button{
     align-self: self-end;
}