.sidebar-instruction {
    background-color: rgb(239, 181, 149);
   
    color: rgb(61, 56, 56);
    width: 300px;
    font-size: 17px;
    padding: 5px;
    display: grid;
    text-align-last: left;
  }

  .sidebar-no-instruction {
    background-color: white;
   
    color:white;
    width: 300px;
    font-size: 17px;
    padding: 5px;
    display: grid;
    text-align-last: left;
  }