.App {
  text-align: center;
  background-color: steelblue;
  
  font-size: 20px;
  color: black;
  /* height: 100vh; */
  
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  
  display: grid;
  grid-template-areas: 
  'backbutton header' 
  'sidebar  content';
  grid-template-columns: 1.3fr 6fr;
}

header{
  grid-area: header;
}

sidebar{
  grid-area: sidebar;
}

content{
  grid-area: content;
  max-width: 100%;
}

back-button{
  grid-area: backbutton;
}