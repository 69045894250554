.sidebar-button {

  background-color: steelblue;
  color: white;
  width: 280px;
  font-size: 20px;
  padding: 10px;

  display: grid;
  text-align-last: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: .5rem
}

.sidebar-button:hover{
  color: rgb(0, 255, 85);
}