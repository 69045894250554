.connect-firebase-content {
    background-color: #ECE9F0;
    /* max-width: 1100px;  */
    max-width: 100%; 
    padding-top: 40px;
    padding-left: 150px;
    display: grid;
    grid-template-columns: auto;
}

.connect-firebase-texts-container {
    height: 70px;
    padding: 20px;
    background-color: rgb(241, 13, 13);
    background-color: rgb(247, 125, 125);
    color: black;
    font-size: 20px;
    max-width: 600px;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 5rem;
}

.connect-firebase-halt {
    height: 400px;
    width: 400px;
}