.app-home-content {
    background-color: #ECE9F0;
    /* max-width: 1100px; */
    padding: 40px;
    padding-left: 18px;
    display: grid;
    grid-template-columns: repeat(1, auto);
    min-height: 100vh;
    width: 100vw;
}

.app-home-img1 {
    width: 1080px;
    height: 610px;
}
