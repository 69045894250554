
.edit-dashboard-texts-container {
    background-color: rgb(240, 192, 166);
    color: rgb(61, 56, 56);

    border: 5px solid;
    border-color: rgb(239, 181, 149);
  
    margin-top: 10px;
    padding: 15px;
    width: 500px;
    height: 60px;

    /* text-align-last: center; */
    font-size: 20px;

    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 4rem;
}

.alien-content {
    background-color: #ECE9F0;
    max-width: 100%;
    padding: 50px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: repeat(4, 270px);
}

.alien-img-content {
    margin-top: 150px;
    width: 250px;
    height: 320px;
}

.img-content {
    margin-left: 20px;
    width: 250px;
    height: 500px;
}