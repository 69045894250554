.about-page {
    font-size: 16px;
    color: #dde726;
   
    display: grid;
    grid-template-areas:
        'about-header'
        'about-content'
        'about-footer';
    grid-template-rows: 70px auto 55px; 
    /* Adjusted row sizes to reflect header and footer heights */

    height: 100vh; /* Ensure the parent element has a defined height */
   
    h1,
    h2,
    h3,
    h4,
    h5,
    b {
        color: #FFFFE0; /* Combined color declarations */
    }

    h1 {
        position: sticky; /* Optional if you want to apply sticky to a specific header */
    }

}

.about-header {
    grid-area: about-header;

    background-color: rgb(95, 161, 153);
    background-color: rgb(94, 157, 102);
    background-color: rgb(133, 170, 157);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    height: 50px;
    padding-left: 4px;

    position: sticky;
    top: 0; /* Must define top position for sticky to work */
    z-index: 1; /* Optional, ensures it stays above content */

    display: flex; /* Flexbox to center content vertically */
    align-items: center; /* Center content vertically */
 
}

.about-content {
    grid-area: about-content;

    height: 400px;
    padding-left: 5px;  
}

.about-footer {
    grid-area: about-footer;

    background-color: rgb(95, 161, 153);
    /* height: 50px; */

    position: sticky;
    bottom: 0; /* Must define bottom position for sticky to work */
    z-index: 1; /* Optional, ensures it stays above content */
}

.about-bio{
    color: white;
    display: grid;
    grid-template-areas:
    'about-bio-image about-bio-text'
    'sidebar content';
    grid-template-columns: 1fr 6fr;
}

.about-bio-image{
    margin-top: 3px;
    grid-area: about-bio-image;
    width: 120px;
    height: 100px;
}

.about-bio-text{
    grid-area: about-bio-text;
    padding: 4px;
    display: flex;
    align-items: flex-end;
}


.about-links{
    background-color: rgba(243, 173, 233, 0.379);
    color: white;
    padding: 6px;
    display: flex;
    align-items: center;
}