.App-header {
  background-color: steelblue;
  cursor:default;

  font-size: 20px;
  color: white;

  display: grid;
  grid-template-rows: 50px;
  align-items: center;
  justify-self: left;
}