
.manage-dashboards-texts-container {
    background-color: rgb(239, 181, 149);
    background-color: rgb(240, 192, 166);
    color: rgb(61, 56, 56);

    border: 5px solid;
    border-color: rgb(239, 181, 149);
  
    margin-top: 10px;
    padding: 15px;
    width: 450px;
    height: 60px;
    
    text-align-last: center;
    font-size: 20px;

    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: 4rem;
}