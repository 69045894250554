
.sidebar{
  height: 100vh;
  grid-area: 'sidebar';
  background-color: white;
  max-width: 100%;
  padding: 40px;
  
  display: grid;
  grid-template-rows: repeat(7, 40px);
  gap:10px;
  align-items: center;
}

.instruction-container {
  background-color: rgb(228, 89, 215);
  margin-top: 200px;
  padding: 4px;
}