.home-content {
    height: 87vh;
  

    background-color: #E9ECF0;
    max-width: auto;
    padding-top: 50px;

    display: grid;
    grid-template-areas:
        'gleft-content  gright-content'
        'gfooter gfooter';

    grid-template-columns: 2fr 6fr;
    grid-template-rows: 1fr auto;
}

left-content {
    grid-area: gleft-content;
    padding-left: 250px;
    padding-top: 10px;
}

right-content {
    grid-area: gright-content;

}

footer {
    grid-area: gfooter;
    color: gray;
    text-align: center;
    padding: 10px;
}

.home-img-added {
    /* Added */
    margin-top: 48px;
    width: 230px;
    height: 430px;
    padding: 5px;
}

.home-img {
    margin-top: 48px;
    width: 270px;
    height: 500px;
}

.home-texts-container {
    margin-top: 20px;
    /* margin-left: 35px; */
    margin-left: 100px;
    margin-bottom: 10px;

    padding: 20px;
    max-width: 550px;

    font-family: 'Times New Roman', Times, serif;
    background-color: rgb(227, 181, 96);
    background-color: steelblue;
    color: rgb(26, 22, 22);
    color: white;
    /* color: lightgrey;
    color: silver; */
    font-size: 27px;

    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 5rem;
}

.button-content {
    background-color: steelblue;
    color: white;
    font-size: 20px;

    width: 400px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 30px;


    text-align-last: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 1rem
}

.button-content:hover {
    color: rgb(0, 255, 85);
}

.elink {
    text-decoration: none;
    color: white;

}

.elink:hover {
    color: rgb(0, 255, 85);
}

.home-content-text {
    margin-left: 100px;
    max-width: 750px;
    line-height: 1.3;
    padding-bottom: 5px;
}