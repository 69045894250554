.video-content {
  
    background-color: #8debcd;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    
    display: grid;
    grid-template-columns: repeat(4, 300px);
    grid-template-columns: repeat(auto-fill, minmax(1px, 320px));
    
}

.video-sidebar{
   
    height: 100vh;
    grid-area: 'sidebar';
    background-color: white;
    max-width: 100%;
    padding: 40px;
    
    display: grid;
    grid-template-rows: repeat(7, 40px);
    gap:10px;
    align-items: center;
}
.hidden {
    display:none;
}