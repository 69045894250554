.video-container {
    background-color: #8debcd;
    background-color: #121312;
    padding: 1px;
    margin: 4px;
    border-radius: .5em;
}
.video-title{
    color: black;
    color: rgb(251, 64, 64);
}